<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card>
        <v-card-title>
          {{ $t('messages.role') }}
        </v-card-title>
        <div class="table-toolbar">
          <remove
            v-if="selected.length > 0 && $can('role.delete')"
            @click="remove"
          />
          <add
            v-if="$can('role.create')"
            :to="{ name: 'dashboard.role-create' }"
          />
        </div>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="roles.data"
          :server-items-length="roles.total"
          :options.sync="pagination"
          :items-per-page="parseInt(roles.per_page)"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false }"
          :loading="loading"
          :show-select="!!$can('role.delete')"
          must-sort
          class="elevation-1"
          @update:options="getRoles"
        >
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template
            v-if="$can('role.update')"
            #[`item.action`]="{ item }"
          >
            <DataTableAction
              :text="$t('action.edit')"
              icon="mdi-pencil"
              @click="$router.push({ name: 'dashboard.role-update', params: { id: item.id }})"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import pagination from '@/mixins/pagination'
import DataTableAction from '@/components/table-actions/VDataTableAction'
import { add, remove } from '@/components/actions'
import authHeader from '@/utils'

export default {
  name: 'Role',
  components: {
    add, remove, DataTableAction
  },
  mixins: [pagination],
  data () {
    return {
      selected: [],
      loading: true,
      headers: [
        { text: '#', value: 'id' },
        { text: this.$t('messages.name'), value: 'display_name' },
        { text: this.$t('messages.description'), value: 'description' },
        { text: this.$t('messages.system_name'), value: 'name' },
        { text: this.$t('messages.created_at'), value: 'created_at' },
        { text: this.$t('messages.updated_at'), value: 'updated_at' },
        this.$can('role.update') ? { text: this.$t('messages.actions'), value: 'action', sortable: false, width: '15px', align: 'right' } : {}
      ],
      roles: {
        data: []
      }
    }
  },
  mounted () {
    this.getRoles()
  },
  methods: {
    getRoles () {
      this.$http
        .get('/roles' + this.datatableOptions, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then((response) => {
          this.loading = false
          this.roles = response.data
        })
    },
    remove () {
      this.$http
        .delete('/roles', { data: { id: this.selected.map(val => val.id) }, headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(() => {
          this.selected = []
          this.$store.commit('setNotification', { color: 'error', message: this.$t('action.delete_success') })
        })
    }
  }
}
</script>
